import React, { useContext, useState } from 'react';
import './subscribe.scss';
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { UsersContext } from 'Providers/UsersProvider';
import avatar from 'assets/personavatar.png';
import { firestore, storage } from 'FirebaseConfig';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FaUserEdit } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import CheckoutForm from 'Providers/StripeProvider';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { functions } from 'FirebaseConfig';
import {
  REACT_APP_STRIPE_PUBLIC_KEY,
  REACT_APP_STRIPE_KEY_TEAMMEMBER_PLAN,
  REACT_APP_STRIPE_KEY_ORGANIZER_PLAN,
} from 'utils/config';
import {
  SUBSCRIPTIONS_ACCOUNT,
  SUBSCRIPTIONS_SUBSCRIPTIONS,
  SUBSCRIPTIONS_SETTINGS,
  SUBSCRIPTIONS_FREEPLAN_DESCRPTION,
  SUBSCRIPTIONS_TEAM_MEMBER_DESCRIPTION,
  SUBSCRIPTIONS_ORGANIZER_DESCRIPTION,
  SUBSCRIPTIONS_FREEPLAN,
  SUBSCRIPTIONS_TEAM_MEMBER,
  SUBSCRIPTIONS_ORGANIZER,
  SUBSCRIPTIONS_FREEPLAN_PRICE,
  SUBSCRIPTIONS_TEAM_MEMBER_DESCRIPTION_PRICE,
  SUBSCRIPTIONS_ORGANIZER_DESCRIPTION_PRICE,
  SUBSCRIPTIONS_NAME,
  SUBSCRIPTIONS_EMAIL,
  SUBSCRIPTIONS_SUBSCRIPTION,
  EDIT_ACCOUNT,
  UPGRADE_TO,
  SUBSCRIPTIONS_CONTACT,
  SUBSCRIPTIONS_3_MONTH,
  SUBSCRIPTIONS_6_MONTH,
  SUBSCRIPTIONS_POWERED,
  SUBSCRIPTIONS_BY,
  SUBSCRIPTIONS_ACCOUNT_INFO,
  SUBSCRIPTIONS_PROFILE_PICTURE,
  SUBSCRIPTIONS_SUBMIT,
} from 'constants/strings';
import { Rights } from 'globals/Rights';

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY!);

interface ProfileProps {
  profilename?: string;
  profilepicture?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 300,
    },
  })
);

const Subscribe: React.FC<ProfileProps> = () => {
  const [selectedPlan, setSelectedPlan] = useState<string>();

  const [stripePlan, setStripePlan] = useState<string>();

  const [stripeInfo, setStripeInfo] = useState<string>();

  const history = useHistory();

  const location = useLocation().pathname;

  const classes = useStyles();

  const [fullName, setFullName] = useState('');

  const [email, setEmail] = useState('');

  const { profile } = useContext(UsersContext);

  const uid = profile && profile.id;

  const [imageSrc, setImageSrc] = useState(true);
  const addDefaultsrc = (): void => {
    setImageSrc(!imageSrc);
  };

  const handleBack = (): void => {
    history.push('/');
  };

  let imageInput: File;

  const userRef = firestore.doc(`users/${uid}`);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (e.target.files) {
      imageInput = e?.target?.files[0];
    }
  };

  const handleSubmit = (): void => {
    if (fullName === '' && email === '' && imageInput == null) {
      history.push('/account');
    } else if (uid) {
      firestore
        .collection('users')
        .doc(uid)
        .update({
          display: fullName || profile?.displayName,
          displayName: fullName || profile?.displayName,
          email: email || profile?.email,
        });
      imageInput &&
        storage
          .ref()
          .child('user-profiles')
          .child(uid)
          .child(imageInput && imageInput.name)
          .put(imageInput)
          .then(response => response.ref.getDownloadURL())
          .then(photoURL => userRef.update({ photoURL }));
      history.push('/account');
    }
  };

  const revertFreePlan = functions.httpsCallable('revertFreePlan');

  const handleFreePlan = (): void => {
    revertFreePlan({
      uid: profile?.uid,
      stripeId: profile?.stripeId,
    });
  };
  const handleTeamMember = (): void => {
    setStripeInfo(SUBSCRIPTIONS_3_MONTH);
    setSelectedPlan('TEAM MEMBER');
    history.push('/billing');
    setStripePlan(REACT_APP_STRIPE_KEY_TEAMMEMBER_PLAN!);
  };
  const handleOrganizer = (): void => {
    setStripeInfo(SUBSCRIPTIONS_6_MONTH);
    setSelectedPlan('ORGANIZER');
    history.push('/billing');
    setStripePlan(REACT_APP_STRIPE_KEY_ORGANIZER_PLAN!);
  };

  const HasSubscriptionMainRoutes = ['/subscription'].includes(location);

  const HasSubscriptionRoutes = ['/subscription', '/billing'].includes(
    location
  );

  const HasAccountRoutes = ['/account', '/edit'].includes(location);

  const HasFreePlanSubscription = profile?.subscription === Rights.FreePlan;

  const HasOrganizerSubscription = profile?.subscription === Rights.Organizer;

  const HasTeamMemberSubscription = profile?.subscription === Rights.TeamMember;

  return (
    <React.Fragment>
      {profile && (
        <div className="subscribe-container">
          {HasSubscriptionMainRoutes && (
            <p className="contant-meetzoo">
              <a className="email-action" href="mailto: payments@meetzoo.com">
                {SUBSCRIPTIONS_CONTACT}
              </a>
            </p>
          )}
          <div className="settings">
            <IoIosArrowBack className="back" onClick={handleBack} />
            <p className="title">{SUBSCRIPTIONS_SETTINGS}</p>
          </div>
          <div className="row">
            <div className="settings">
              <Link to="/account" className="link">
                <p className={HasAccountRoutes ? 'active-menu' : 'none'}>
                  {SUBSCRIPTIONS_ACCOUNT}
                </p>
              </Link>
            </div>
            <div className="settings">
              <Link to="/subscription" className="link">
                <p className={HasSubscriptionRoutes ? 'active-menu' : 'none'}>
                  {SUBSCRIPTIONS_SUBSCRIPTIONS}
                </p>
              </Link>
            </div>
            <div className="settings"></div>
          </div>
          <div className="subscription-container">
            <Switch>
              <Route path="/subscription">
                <div className="free-plan">
                  <div className="plan">
                    <h2>
                      <strong>{SUBSCRIPTIONS_FREEPLAN}</strong>
                    </h2>
                    <h1>
                      <p>£</p>
                      {SUBSCRIPTIONS_FREEPLAN_PRICE}
                      <p>/mo</p>
                    </h1>
                    <p className="text">{SUBSCRIPTIONS_FREEPLAN_DESCRPTION}</p>
                    <button
                      onClick={handleFreePlan}
                      className={
                        HasFreePlanSubscription ? 'button' : 'button-select'
                      }
                    >
                      {HasFreePlanSubscription ? 'CURRENT PLAN' : 'CHOOSE PLAN'}
                    </button>
                  </div>
                </div>
                <div className="team-member">
                  <div className="plan">
                    <h2>
                      <strong>{SUBSCRIPTIONS_TEAM_MEMBER}</strong>
                    </h2>
                    <h1>
                      <p>£</p>
                      {SUBSCRIPTIONS_TEAM_MEMBER_DESCRIPTION_PRICE}
                      <p>/mo</p>
                    </h1>
                    <p className="text">
                      {SUBSCRIPTIONS_TEAM_MEMBER_DESCRIPTION}
                    </p>
                    <button
                      onClick={handleTeamMember}
                      className={
                        HasTeamMemberSubscription ? 'button' : 'button-select'
                      }
                    >
                      {HasTeamMemberSubscription
                        ? 'CURRENT PLAN'
                        : 'CHOOSE PLAN'}
                    </button>
                  </div>
                </div>
                <div className="organizer">
                  <div className="plan">
                    <h2>
                      <strong>{SUBSCRIPTIONS_ORGANIZER}</strong>
                    </h2>
                    <h1>
                      <p>£</p>
                      {SUBSCRIPTIONS_ORGANIZER_DESCRIPTION_PRICE}
                      <p>/mo</p>
                    </h1>
                    <p className="text">
                      {SUBSCRIPTIONS_ORGANIZER_DESCRIPTION}
                    </p>
                    <button
                      onClick={handleOrganizer}
                      className={
                        HasOrganizerSubscription ? 'button' : 'button-select'
                      }
                    >
                      {HasOrganizerSubscription
                        ? 'CURRENT PLAN'
                        : 'CHOOSE PLAN'}
                    </button>
                  </div>
                </div>
              </Route>
              <Route path="/account">
                <div className="profile">
                  {imageSrc && profile.photoURL !== null ? (
                    <img
                      alt="avatar"
                      src={profile.photoURL}
                      onError={addDefaultsrc}
                    />
                  ) : (
                    <img alt="avatar" src={avatar} />
                  )}
                  <p>
                    {SUBSCRIPTIONS_NAME} <strong>{profile.displayName}</strong>
                  </p>
                  <p>
                    {SUBSCRIPTIONS_EMAIL} <strong>{profile.email}</strong>
                  </p>
                  <p>
                    {SUBSCRIPTIONS_SUBSCRIPTION}{' '}
                    <strong>{profile.subscription}</strong>
                  </p>
                  <Link to="/edit" className="link">
                    <button className="button-select">
                      <FaUserEdit className="icon" />
                      {EDIT_ACCOUNT}
                    </button>
                  </Link>
                </div>
              </Route>
              <Route path="/billing">
                <div className="credit-card">
                  <p>
                    {UPGRADE_TO} <strong>{selectedPlan}</strong>{' '}
                  </p>
                  <div className="card">
                    <Elements stripe={stripePromise}>
                      <CheckoutForm
                        selectedPlan={stripePlan}
                        stripeInfo={stripeInfo}
                      />
                    </Elements>
                  </div>
                  <p>
                    {SUBSCRIPTIONS_POWERED} <strong>{SUBSCRIPTIONS_BY}</strong>
                  </p>
                </div>
              </Route>
              <Route path="/edit">
                <div className="profile">
                  <p>{SUBSCRIPTIONS_ACCOUNT_INFO}</p>
                  <TextField
                    id="standard-helperText"
                    label="Full Name"
                    defaultValue={profile.displayName}
                    margin="normal"
                    className={classes.textField}
                    onChange={e => setFullName(e.currentTarget.value)}
                  />
                  <TextField
                    id="standard-helperText"
                    label="Email"
                    defaultValue={profile.email}
                    margin="normal"
                    className={classes.textField}
                    onChange={e => setEmail(e.currentTarget.value)}
                  />
                  <p>{SUBSCRIPTIONS_PROFILE_PICTURE}</p>
                  <form>
                    <input
                      type="file"
                      className="input"
                      accept="image/png, image/jpeg"
                      onChange={handleImageChange}
                    />
                  </form>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {SUBSCRIPTIONS_SUBMIT}
                  </Button>
                </div>
              </Route>
            </Switch>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default React.memo(Subscribe);
