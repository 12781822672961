import React, { useContext, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CreatePaymentMethodCardData } from '@stripe/stripe-js';
import '../components/SubscribeComp/subscribe.scss';
import { functions } from 'FirebaseConfig';
import { UsersContext } from './UsersProvider';
import { useHistory } from 'react-router-dom';
import {
  SUBSCRIPTIONS_PAY_CARD,
  SUBSCRIPTIONS_ENTER_EMAIL,
  SUBSCRIPTIONS_ENTER_ADRESS,
} from 'constants/strings';

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#62778c',
      fontFamily: '"SF Display", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      '::placeholder': {
        color: '#9b9b9b',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

interface CheckoutProps {
  selectedPlan?: string;
  stripeInfo?: string;
}

const CheckoutForm: React.FC<CheckoutProps> = ({
  selectedPlan,
  stripeInfo,
}: CheckoutProps) => {
  const { profile } = useContext(UsersContext);

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [enterEmail, setEnterEmail] = useState(profile?.email);
  const [enterName, setEnterName] = useState(profile?.displayName);

  const handleEmail = (event: {
    target: { value: React.SetStateAction<string | undefined> };
  }): void => {
    setEnterEmail(event.target.value);
  };

  const handleName = (event: {
    target: { value: React.SetStateAction<string | undefined> };
  }): void => {
    setEnterName(event.target.value);
  };

  // Handle form submission.
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: enterEmail,
        name: enterName,
      },
    } as CreatePaymentMethodCardData);

    const createStripePaymentSubscription = functions.httpsCallable(
      'createStripePaymentSubscription'
    );

    if (profile?.stripeId) {
      createStripePaymentSubscription({
        uid: profile?.id,
        stripeId: profile?.stripeId,
        paymentCardMethodId: paymentMethod && paymentMethod.id,
        subscriptionPlan: selectedPlan,
        firebasePlan: profile?.stripeSubscription,
      });
    }

    history.push('/subscription');
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="pay">{SUBSCRIPTIONS_PAY_CARD}</p>
      <div className="card-element">
        <input
          type="text"
          className="input"
          placeholder={SUBSCRIPTIONS_ENTER_EMAIL}
          value={enterName}
          onChange={handleName}
        />
      </div>
      <div className="card-element">
        <input
          type="text"
          className="input"
          placeholder={SUBSCRIPTIONS_ENTER_ADRESS}
          value={enterEmail}
          onChange={handleEmail}
        />
      </div>
      <div className="card-element">
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      <div className="button-pay">
        <button className="submit" type="submit" disabled={!stripe}>
          {stripeInfo}
        </button>
      </div>
    </form>
  );
};

export default CheckoutForm;
