// Firebase contants
export const MEETINGS_COLLECTION = 'meetings';
export const NOTES_COLLECTION = 'notes';
export const USERS_COLLECTION = 'users';
export const UI_COLLECTION = 'ui';

// UI components constants

// Sidebar
export const SIDEBAR_MEETING = 'Meetings';
export const SIDEBAR_ACTIONS = 'Actions';
export const SIDEBAR_SETTINGS = 'Settings';
// Meetings
export const MEETINGS_MEETINGS = 'Meetings';
export const MEETINGS_ALL = 'All';
export const MEETINGS_ATTENDED_BY_ME = 'Attended by me';
export const MEETINGS_ORGANISED_BY_ME = 'Organised by me';
export const MEETINGS_SEARCH = 'Search';
export const MEETINGS_NEW = '+ New';
export const MEETINGS_LOGOUT = 'Logout';

export const MEETINGS_LATER = 'Later';
export const MEETINGS_NEXT_MONTH = 'Next Month';
export const MEETINGS_NEXT_WEEK = 'Next Week';
export const MEETINGS_THIS_WEEK = 'This Week';
export const MEETINGS_LAST_WEEK = 'Last Week';
export const MEETINGS_EARLIER_THIS_MONTH = 'Earlier this Month';
export const MEETINGS_LATER_THIS_MONTH = 'Later this Month';
export const MEETINGS_LAST_MONTH = 'Last Month';
export const MEETINGS_LAST_YEAR = 'Last Year';
export const MEETINGS_EARLIER_THIS_YEAR = 'Earlier this Year';

export const MEETING_DESCRIPTION = 'Type your meeting purpose here';
export const MEETING_PEOPLE = 'People';
export const MEETING_NOTES = 'Notes';
export const MEETING_NAME = 'Name';
export const MEETING_SHARED_MEETINGS = 'Shared Meetings';
export const MEETING_LAST_MEETINGS = 'Most recent';
export const MEETING_ACTIONS_ASSIGNED_TO_ME = 'Actions assigned to me';
export const MEETING_ACTIONS_ASSIGNED_BY_ME = 'Actions assigned by me';
export const MEETING_ADD_PERSON = 'Type person´s name or email here';
export const MEETING_NO_TITLE = 'No meeting';

// Actions
export const ACTION_ADD = 'Type your note here use\n@name to assign an action';
export const ACTION_ACTIONS = 'Actions';
export const ACTION_SEARCH = 'Search';
export const ACTION_SHOW_COMPLETED = 'Show completed';
export const ACTION_ALL = 'All';
export const ACTION_ASSIGNED_TO_ME = 'Assigned to me';
export const ACTION_ASSIGNED_BY_ME = 'Assigned by me';

// Subscriptions
export const SUBSCRIPTIONS_ACCOUNT = 'Account';
export const SUBSCRIPTIONS_SUBSCRIPTIONS = 'Subscription';
export const SUBSCRIPTIONS_BILLING = 'Billing';
export const SUBSCRIPTIONS_SETTINGS = 'Settings';
export const SUBSCRIPTIONS_FREEPLAN = 'FREEPLAN';
export const SUBSCRIPTIONS_FREEPLAN_DESCRPTION =
  'View agenda, notes and actions from meetings organized by others';
export const SUBSCRIPTIONS_FREEPLAN_PRICE = '0';
export const SUBSCRIPTIONS_TEAM_MEMBER = 'TEAM MEMBER';
export const SUBSCRIPTIONS_TEAM_MEMBER_DESCRIPTION =
  'Collaborate by sharing agenda items, notes and actions';
export const SUBSCRIPTIONS_TEAM_MEMBER_DESCRIPTION_PRICE = '2.99';
export const SUBSCRIPTIONS_ORGANIZER = 'ORGANIZER';
export const SUBSCRIPTIONS_ORGANIZER_DESCRIPTION =
  'Create meetings and invite others to collaborate with you';
export const SUBSCRIPTIONS_ORGANIZER_DESCRIPTION_PRICE = '5.99';
export const SUBSCRIPTIONS_CURRENT_PLAN = 'CURRENT PLAN';
export const SUBSCRIPTIONS_CHOOSE_PLAN = 'CHOOSE PLAN';
export const SUBSCRIPTIONS_NAME = 'Name:';
export const SUBSCRIPTIONS_EMAIL = 'Email:';
export const SUBSCRIPTIONS_SUBSCRIPTION = 'Subscription:';
export const EDIT_ACCOUNT = 'Edit account';
export const UPGRADE_TO = 'Change subscription to';
export const SUBSCRIPTIONS_CONTACT =
  'For subscription and billing queries please contact payments@meetzoo.com';
export const SUBSCRIPTIONS_3_MONTH = 'Pay £ 2.99 /Month';
export const SUBSCRIPTIONS_6_MONTH = 'Pay £ 5.99 /Month';
export const SUBSCRIPTIONS_POWERED = ' Powered by ';
export const SUBSCRIPTIONS_BY = 'Stripe';
export const SUBSCRIPTIONS_ACCOUNT_INFO = 'Account info';
export const SUBSCRIPTIONS_PROFILE_PICTURE = 'Profile picture';
export const SUBSCRIPTIONS_PAY_CARD = 'Pay with card';
export const SUBSCRIPTIONS_ENTER_EMAIL = 'Enter Full Name';
export const SUBSCRIPTIONS_ENTER_ADRESS = 'Enter Email address';
export const SUBSCRIPTIONS_SUBMIT = 'Submit';
