import React, { ReactNode } from 'react';
import './home.scss';
import MeetingItem from './MeetingItem';

interface MeetingSectionProps {
  meetings: firebase.firestore.DocumentReference[];
  title: string;
}

const MeetingSection: React.FC<MeetingSectionProps> = ({
  meetings,
  title,
}: MeetingSectionProps) => {
  return (
    <div className="week-container">
      {meetings.length > 0 && <p className="w1">{title}</p>}
      {meetings.length > 0 &&
        meetings.map(
          (meetingDocument: firebase.firestore.DocumentReference): ReactNode => (
            <MeetingItem key={meetingDocument.id} meetingDocument={meetingDocument}/>
          )
        )}
    </div>
  );
};

export default React.memo(MeetingSection);
