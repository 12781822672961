import { MeetingModel } from 'models/MeetingModel';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

type MeetingsProviderContextProps = {
  meeting: MeetingModel;
  setMeeting: Dispatch<SetStateAction<MeetingModel>>;
};

export const MeetingsContext = createContext<
  Partial<MeetingsProviderContextProps>
>({});

interface MeetingsProps {
  children: ReactNode;
}

const MeetingsProvider: React.FC<MeetingsProps> = ({
  children,
}: MeetingsProps) => {
  const [meeting, setMeeting] = useState<MeetingModel>({} as MeetingModel);

  return (
    <MeetingsContext.Provider
      value={{
        meeting,
        setMeeting,
      }}
    >
      {children}
    </MeetingsContext.Provider>
  );
};

export default MeetingsProvider;
