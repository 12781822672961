export const SKIP_PREFLIGHT_CHECK = process.env.SKIP_PREFLIGHT_CHECK;
export const PUBLIC_URL = process.env.PUBLIC_URL;

export const REACT_APP_FIREBASE_KEY = process.env.REACT_APP_FIREBASE_KEY;
export const REACT_APP_FIREBASE_DOMAIN = process.env.REACT_APP_FIREBASE_DOMAIN;
export const REACT_APP_FIREBASE_DATABASE =
  process.env.REACT_APP_FIREBASE_DATABASE;
export const REACT_APP_FIREBASE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const REACT_APP_FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const REACT_APP_FIREBASE_SENDER_ID =
  process.env.REACT_APP_FIREBASE_SENDER_ID;
export const REACT_APP_FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const REACT_APP_FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export const REACT_APP_STRIPE_PUBLIC_KEY =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const REACT_APP_STRIPE_KEY_TEAMMEMBER_PLAN =
  process.env.REACT_APP_STRIPE_KEY_TEAMMEMBER_PLAN;
export const REACT_APP_STRIPE_KEY_ORGANIZER_PLAN =
  process.env.REACT_APP_STRIPE_KEY_ORGANIZER_PLAN;

export const REACT_APP_ALGOLIA_ID = process.env.REACT_APP_ALGOLIA_ID;
export const REACT_APP_ALGOLIA_SEARCH_KEY =
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY;

export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const REACT_APP_ALGOLIA_MEETINGS_COLLECTION =
  process.env.REACT_APP_ALGOLIA_MEETINGS_COLLECTION;
export const REACT_APP_ALGOLIA_NOTES_COLLECTION =
  process.env.REACT_APP_ALGOLIA_NOTES_COLLECTION;
export const REACT_APP_ALGOLIA_USERS_COLLECTION =
  process.env.REACT_APP_ALGOLIA_USERS_COLLECTION;
