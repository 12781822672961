import { NoteFilter } from './NoteFilter';

export const userNoteFilter = (filter: NoteFilter, user: string): string => {
  switch (filter) {
    case NoteFilter.All:
      return `connected:${user}`;
    case NoteFilter.AssignedToMe:
      return `assigned:${user}`;
    case NoteFilter.AssignedByMe:
      return `uid:${user}`;
  }
};

export const noteListFilters = (
  isChecked: boolean,
  user: string,
  filter: NoteFilter
): string =>
  `${!isChecked ? 'checked=0 AND' : ''} ${userNoteFilter(filter, user)}`;
