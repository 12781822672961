import React, { useContext } from 'react';
import { RouteProps, Route, Redirect, useLocation } from 'react-router-dom';
import { AuthContext } from 'FirebaseAuthProvider ';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  children?: any;
  exact?: boolean | undefined;
  path?: string | undefined;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: RouteComponent,
  ...rest
}: PrivateRouteProps) => {
  const { currentUser } = useContext(AuthContext);

  const location = useLocation();

  return (
    <Route
      {...rest}
      render={routeProps =>
        currentUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: location.pathname } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
