import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { algolia, algoliaMeetingSearch } from 'AlgoliaConfig';
import {
  MEETINGS_ALL,
  MEETINGS_ATTENDED_BY_ME,
  MEETINGS_EARLIER_THIS_MONTH,
  MEETINGS_EARLIER_THIS_YEAR,
  MEETINGS_LAST_MONTH,
  MEETINGS_LAST_WEEK,
  MEETINGS_LAST_YEAR,
  MEETINGS_LATER,
  MEETINGS_LATER_THIS_MONTH,
  MEETINGS_MEETINGS,
  MEETINGS_NEW,
  MEETINGS_NEXT_MONTH,
  MEETINGS_NEXT_WEEK,
  MEETINGS_ORGANISED_BY_ME,
  MEETINGS_SEARCH,
  MEETINGS_THIS_WEEK,
  UI_COLLECTION,
} from 'constants/strings';
import { firestore } from 'FirebaseConfig';
import { Rights } from 'globals/Rights';
import moment from 'moment';
import {
  endOfNextMonth,
  endOfNextWeek,
  endOfThisMonth,
  endOfThisWeek,
  startOfLastMonth,
  startOfLastWeek,
  startOfThisMonth,
  startOfThisWeek,
  startOfThisYear,
} from 'Providers/TimelineBoundaries';
import { UsersContext } from 'Providers/UsersProvider';
import React, { useContext, useEffect, useState, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { catchErrorSnapshot } from 'utils/utils';
import './home.scss';
import MeetingSection from './MeetingSection';

export enum MeetingFilter {
  All = 'all',
  OrganizedByMe = 'organized-by-me',
  AttendedByMe = 'attended-by-me',
}

export type Meeting = firebase.firestore.DocumentReference;

const MeetingList: React.FC<{}> = () => {
  const [lastWeekMeetings, setLastWeekMeetings] = useState<Meeting[]>([]);

  const [thisWeekMeetings, setThisWeekMeetings] = useState<Meeting[]>([]);

  const [nextWeekMeetings, setNextWeekMeetings] = useState<Meeting[]>([]);

  const [lastMonthMeetings, setLastMonthMeetings] = useState<Meeting[]>([]);
  const [thisMonthEarlierMeetings, setThisMonthEarlierMeetings] = useState<
    Meeting[]
  >([]);
  const [thisMonthLaterMeetings, setThisMonthLaterMeetings] = useState<
    Meeting[]
  >([]);
  const [nextMonthMeetings, setNextMonthMeetings] = useState<Meeting[]>([]);

  const [lastYearMeetings, setLastYearMeetings] = useState<Meeting[]>([]);

  const [thisYearEarlierMeetings, setThisYearEarlierMeetings] = useState<
    Meeting[]
  >([]);
  const [thisYearLaterMeetings, setThisYearLaterMeetings] = useState<Meeting[]>(
    []
  );

  const [filterMeeting, setFilterMeeting] = useState(MeetingFilter.All);

  const [searchMeetingData, setSearchMeetingData] = useState('');

  const { profile } = useContext(UsersContext);

  const uid = profile && profile.id;

  const subscription = profile && profile.subscription;

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }): void => {
    setSearchMeetingData && setSearchMeetingData(event.target.value);
  };

  const title = 'Meeting title';
  const info = '';

  const history = useHistory();

  const addMeeting = (e: { preventDefault: () => void }): void => {
    e.preventDefault();

    const next5Minutes = moment()
      .startOf('minute')
      .add(5, 'minutes');
    next5Minutes.minutes(Math.floor(next5Minutes.minutes() / 5) * 5);
    next5Minutes.format('HH:mm');

    const date = next5Minutes.toDate();

    const MEETINGS_COLLECTION = 'meetings';

    const meeting = {
      date,
      title,
      info,
      organizer: uid,
      users: [uid],
    };

    firestore
      .collection(MEETINGS_COLLECTION)
      .add(meeting)
      .then(newMeeting => {
        history.push(`/meeting/${newMeeting.id}`);
      });
  };

  const callBackLastWeek = (uid: string): void =>
    algoliaMeetingSearch(
      searchMeetingData,
      uid,
      startOfLastWeek < startOfThisMonth ? startOfThisMonth : startOfLastWeek,
      startOfThisWeek,
      filterMeeting,
      setLastWeekMeetings
    );

  useEffect(() => {
    if (uid) {
      callBackLastWeek(uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMeetingData, uid, filterMeeting]);

  const callBackThisWeek = (uid: string): void =>
    algoliaMeetingSearch(
      searchMeetingData,
      uid,
      startOfThisWeek,
      endOfThisWeek,
      filterMeeting,
      setThisWeekMeetings
    );

  useEffect(() => {
    if (uid) {
      algolia.clearCache();
      callBackThisWeek(uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMeetingData, uid, filterMeeting]);

  const callBackNextWeek = (uid: string): void =>
    algoliaMeetingSearch(
      searchMeetingData,
      uid,
      endOfThisWeek,
      endOfNextWeek > endOfThisMonth ? endOfThisMonth : endOfNextWeek,
      filterMeeting,
      setNextWeekMeetings
    );

  useEffect(() => {
    if (uid) {
      algolia.clearCache();
      callBackNextWeek(uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMeetingData, uid, filterMeeting]);

  const callBackLastMonth = (uid: string): void =>
    algoliaMeetingSearch(
      searchMeetingData,
      uid,
      startOfLastMonth,
      startOfThisMonth,
      filterMeeting,
      setLastMonthMeetings
    );

  useEffect(() => {
    if (uid) {
      algolia.clearCache();
      callBackLastMonth(uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMeetingData, uid, filterMeeting]);

  const callBackThisMonthEarlier = (uid: string): void =>
    algoliaMeetingSearch(
      searchMeetingData,
      uid,
      startOfThisMonth,
      startOfThisWeek,
      filterMeeting,
      setThisMonthEarlierMeetings
    );

  useEffect(() => {
    if (uid) {
      algolia.clearCache();
      callBackThisMonthEarlier(uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMeetingData, uid, filterMeeting]);

  const callBackThisMonthLater = (uid: string): void =>
    algoliaMeetingSearch(
      searchMeetingData,
      uid,
      endOfNextWeek > endOfThisMonth ? endOfThisMonth : endOfNextWeek,
      endOfThisMonth,
      filterMeeting,
      setThisMonthLaterMeetings
    );

  useEffect(() => {
    if (uid) {
      algolia.clearCache();
      callBackThisMonthLater(uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMeetingData, uid, filterMeeting]);

  const callBackNextMonth = (uid: string): void =>
    algoliaMeetingSearch(
      searchMeetingData,
      uid,
      endOfThisMonth,
      endOfNextMonth,
      filterMeeting,
      setNextMonthMeetings
    );

  useEffect(() => {
    if (uid) {
      algolia.clearCache();
      callBackNextMonth(uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMeetingData, uid, filterMeeting]);

  const callBackLastYear = (uid: string): void =>
    algoliaMeetingSearch(
      searchMeetingData,
      uid,
      undefined,
      startOfThisYear > startOfLastMonth ? startOfLastMonth : startOfThisYear,
      filterMeeting,
      setLastYearMeetings
    );

  useEffect(() => {
    if (uid) {
      algolia.clearCache();
      callBackLastYear(uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMeetingData, uid, filterMeeting]);

  const callBackThisYearEarlier = (uid: string): void =>
    algoliaMeetingSearch(
      searchMeetingData,
      uid,
      startOfThisYear,
      startOfLastMonth,
      filterMeeting,
      setThisYearEarlierMeetings
    );

  useEffect(() => {
    if (uid) {
      algolia.clearCache();
      callBackThisYearEarlier(uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMeetingData, uid, filterMeeting]);

  const callBackThisYearLater = (uid: string): void =>
    algoliaMeetingSearch(
      searchMeetingData,
      uid,
      endOfNextMonth,
      undefined,
      filterMeeting,
      setThisYearLaterMeetings
    );

  useEffect(() => {
    if (uid) {
      algolia.clearCache();
      callBackThisYearLater(uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMeetingData, uid, filterMeeting]);

  useEffect(() => {
    if (uid) {
      const subscription = firestore
        .collection(UI_COLLECTION)
        .doc('meeting_list_component')
        .onSnapshot(() => {
          algolia.clearCache();
          callBackLastWeek(uid);
          callBackThisWeek(uid);
          callBackNextWeek(uid);
          callBackLastMonth(uid);
          callBackThisMonthEarlier(uid);
          callBackThisMonthLater(uid);
          callBackNextMonth(uid);
          callBackLastYear(uid);
          callBackThisYearEarlier(uid);
          callBackThisYearLater(uid);
        });
      return () => catchErrorSnapshot(subscription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return (
    <div className="home">
      {profile && (
        <React.Fragment>
          <div className="horizontal-line"></div>
          <div className="meetings">
            <p>{MEETINGS_MEETINGS}</p>
          </div>
          <div className="search">
            <input
              type="text"
              placeholder={MEETINGS_SEARCH}
              value={searchMeetingData}
              onChange={handleChange}
              className="input"
            />
          </div>
          {subscription === Rights.Organizer && (
            <div className="new-add-container">
              <div className="new">
                <Button
                  onClick={addMeeting}
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: '#00ADEE', boxShadow: 'none' }}
                >
                  <p>{MEETINGS_NEW}</p>
                </Button>
              </div>
              <div className="add">
                <Fab
                  onClick={addMeeting}
                  size="small"
                  color="primary"
                  aria-label="add"
                  style={{ backgroundColor: '#00ADEE' }}
                >
                  <AddIcon />
                </Fab>
              </div>
            </div>
          )}
          <div
            className="all"
            onClick={() =>
              setFilterMeeting && setFilterMeeting(MeetingFilter.All)
            }
          >
            <p
              className={
                filterMeeting === MeetingFilter.All
                  ? 'active-main-menu'
                  : 'none'
              }
            >
              {MEETINGS_ALL}
            </p>
          </div>
          <div
            className="organised"
            onClick={() =>
              setFilterMeeting && setFilterMeeting(MeetingFilter.OrganizedByMe)
            }
          >
            <p
              className={
                filterMeeting === MeetingFilter.OrganizedByMe
                  ? 'active-main-menu'
                  : 'none'
              }
            >
              {MEETINGS_ORGANISED_BY_ME}
            </p>
          </div>
          <div
            className="attended"
            onClick={() =>
              setFilterMeeting && setFilterMeeting(MeetingFilter.AttendedByMe)
            }
          >
            <p
              className={
                filterMeeting === MeetingFilter.AttendedByMe
                  ? 'active-main-menu'
                  : 'none'
              }
            >
              {MEETINGS_ATTENDED_BY_ME}
            </p>
          </div>
          <div className="week-container">
            <MeetingSection
              title={MEETINGS_LATER}
              meetings={thisYearLaterMeetings}
            />
            <MeetingSection
              title={MEETINGS_NEXT_MONTH}
              meetings={nextMonthMeetings}
            />
            <MeetingSection
              title={MEETINGS_LATER_THIS_MONTH}
              meetings={thisMonthLaterMeetings}
            />
            <MeetingSection
              title={MEETINGS_NEXT_WEEK}
              meetings={nextWeekMeetings}
            />
            <MeetingSection
              title={MEETINGS_THIS_WEEK}
              meetings={thisWeekMeetings}
            />
            <MeetingSection
              title={MEETINGS_LAST_WEEK}
              meetings={lastWeekMeetings}
            />
            <MeetingSection
              title={MEETINGS_EARLIER_THIS_MONTH}
              meetings={thisMonthEarlierMeetings}
            />
            <MeetingSection
              title={MEETINGS_LAST_MONTH}
              meetings={lastMonthMeetings}
            />
            <MeetingSection
              title={MEETINGS_EARLIER_THIS_YEAR}
              meetings={thisYearEarlierMeetings}
            />
            <MeetingSection
              title={MEETINGS_LAST_YEAR}
              meetings={lastYearMeetings}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default MeetingList;
