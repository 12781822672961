import algoliasearch from 'algoliasearch';
import {
  REACT_APP_ALGOLIA_ID,
  REACT_APP_ALGOLIA_SEARCH_KEY,
  REACT_APP_ALGOLIA_MEETINGS_COLLECTION,
  REACT_APP_ALGOLIA_NOTES_COLLECTION,
} from 'utils/config';
import { MeetingFilter, Meeting } from 'components/Meeting/MeetingList';
import { Dispatch, SetStateAction } from 'react';

import { meetingsFilter } from 'Providers/FilterUtils';
import {
  algoliaHitsToMeetingDocumentReference,
  algoliaHitsToNoteDocumentReference,
} from 'utils/utils';
import { noteListFilters } from 'components/Note/NoteUtils';
import { Note } from 'components/Note/NoteList';
import { NoteFilter } from 'components/Note/NoteFilter';

export const algolia = algoliasearch(
  REACT_APP_ALGOLIA_ID!,
  REACT_APP_ALGOLIA_SEARCH_KEY!
);

export const algoliaMeetingSearch = (
  search: string,
  uid: string,
  dateStart: number | undefined,
  dateEnd: number | undefined,
  filterMeeting: MeetingFilter | undefined,
  setMeetings: Dispatch<SetStateAction<Meeting[]>>
): void => {
  algolia
    .initIndex(REACT_APP_ALGOLIA_MEETINGS_COLLECTION!)
    .search(search, {
      filters: meetingsFilter(uid, dateStart, dateEnd, filterMeeting),
    })
    .then(({ hits }) =>
      setMeetings(algoliaHitsToMeetingDocumentReference(hits))
    );
};

export const algoliaNoteSearch = (
  search: string,
  uid: string,
  isChecked: boolean,
  filter: NoteFilter,
  setNotes: Dispatch<SetStateAction<Note[]>>
): void => {
  algolia
    .initIndex(REACT_APP_ALGOLIA_NOTES_COLLECTION!)
    .search(search, {
      filters: noteListFilters(isChecked, uid, filter),
    })
    .then(({ hits }) => {
      setNotes(algoliaHitsToNoteDocumentReference(hits));
    });
};
