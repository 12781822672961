import React from 'react';
import './home.scss';
import Truncate from 'react-truncate';

interface MeetingTimeLineProps {
  meetingDate: string | undefined;
  meetingDay: string | undefined;
  meetingInfo: string | undefined;
}

const MeetingTimeLine: React.FC<MeetingTimeLineProps> = ({
  meetingDate,
  meetingDay,
  meetingInfo,
}: MeetingTimeLineProps) => {
  let dayToDisplay;
  switch (meetingDate && meetingDay) {
    case 'Monday':
      dayToDisplay = 'Mon';
      break;
    case 'Tuesday':
      dayToDisplay = 'Tue';
      break;
    case 'Wednesday':
      dayToDisplay = 'Wed';
      break;
    case 'Thursday':
      dayToDisplay = 'Thu';
      break;
    case 'Friday':
      dayToDisplay = 'Fri';
      break;
    case 'Saturday':
      dayToDisplay = 'Sat';
      break;
    case 'Sunday':
      dayToDisplay = 'Sun';
      break;
  }

  return (
    <div className="week">
      <p className="w2">
        {dayToDisplay && dayToDisplay} {meetingDate}
      </p>
      <div className="truncate">
        <Truncate lines={1}>{meetingInfo}</Truncate>
      </div>
      <p className="not-truncate">{meetingInfo}</p>
    </div>
  );
};

export default React.memo(MeetingTimeLine);
