import React, { useContext, useState, useEffect, ChangeEvent } from 'react';
import './person.scss';
import { IoIosAddCircleOutline } from 'react-icons/io';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import { MEETING_ADD_PERSON, MEETINGS_COLLECTION } from 'constants/strings';

import { UserModel } from 'models/UserModel';
import firebase from 'firebase';
import { firestore } from '../../FirebaseConfig';

import { algolia } from 'AlgoliaConfig';
import { MeetingsContext } from 'Providers/MeetingsProvider';
import { REACT_APP_ALGOLIA_USERS_COLLECTION } from 'utils/config';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00ADEE',
    },
  },
});

const PersonAdd: React.FC<{}> = () => {
  const { meeting } = useContext(MeetingsContext);

  const [searchText, setSearchText] = useState<string | undefined>('');

  const [users, setUsers] = useState<UserModel[]>([]);

  const [userUID, setUserUID] = useState<string | undefined>(undefined);

  const searchUser = (event: {
    target: { value: React.SetStateAction<string | undefined> };
  }): void => {
    setSearchText(event.target.value);
  };

  const selectUser = (
    event: ChangeEvent<{}>,
    value: string | UserModel | null
  ): void => {
    const model = value as UserModel;
    if (model?.objectID) {
      setUserUID(model.objectID);
      setSearchText(model.displayName);
    }
  };

  const handleSubmit = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    if (userUID && meeting) {
      firestore
        .collection(MEETINGS_COLLECTION)
        .doc(meeting.id)
        .update({
          users: firebase.firestore.FieldValue.arrayUnion(userUID),
        });
    }
    setSearchText('');
    setUsers([]);
  };

  useEffect(() => {
    if (searchText) {
      algolia
        .initIndex(REACT_APP_ALGOLIA_USERS_COLLECTION!)
        .search(searchText)
        .then(({ hits }) => {
          setUsers(hits);
        });
    } else setUsers([]);
  }, [searchText]);

  return (
    <div className="person-add">
      <form onSubmit={handleSubmit}>
        <div className="user-add">
          <IoIosAddCircleOutline className="add-icon" onClick={handleSubmit} />
          <div style={{ width: 300 }}>
            <ThemeProvider theme={theme}>
              <Autocomplete
                closeIcon={false}
                id="users-firebasex"
                freeSolo
                clearOnEscape={true}
                autoComplete={false}
                autoHighlight={true}
                onChange={selectUser}
                options={users}
                inputValue={searchText}
                filterOptions={options => options}
                getOptionLabel={option => option.displayName ?? "-"}
                renderInput={params => (
                  <TextField
                    {...params}
                    margin="normal"
                    placeholder={MEETING_ADD_PERSON}
                    variant="outlined"
                    onChange={searchUser}
                    value={searchText}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: 11,
                      },
                    }}
                  />
                )}
              />
            </ThemeProvider>
          </div>
        </div>
      </form>
    </div>
  );
};

export default React.memo(PersonAdd);
