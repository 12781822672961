import React from 'react';
import './header.scss';
import logo from 'assets/logo_text.svg';

const Headerlogin: React.FC = () => {
  return (
    <header className="header-login">
      <div className="brand-login">
        <img className="brand-logo" src={logo} alt="Logo" />
      </div>
    </header>
  );
};

export default React.memo(Headerlogin);
