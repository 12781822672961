import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import logo from 'assets/logo_text.svg';
import { IoMdMenu } from 'react-icons/io';
import { auth } from 'FirebaseConfig';
import {
  SIDEBAR_MEETING,
  SIDEBAR_ACTIONS,
  SIDEBAR_SETTINGS,
  MEETINGS_LOGOUT,
} from 'constants/strings';

const Header: React.FC<{}> = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const toogleNav = (): void => {
    setIsNavVisible(!isNavVisible);
  };

  const handleLogout = (): void => {
    auth.signOut();
  };

  return (
    <header className="header">
      <div className="brand">
        <img src={logo} alt="Logo" className="hide" />
      </div>
      {isNavVisible && (
        <div className="header-menu-list">
          <Link to="/" className="link" onClick={() => setIsNavVisible(false)}>
            <p>{SIDEBAR_MEETING}</p>
          </Link>
          <Link
            to="/actions"
            className="link"
            onClick={() => setIsNavVisible(false)}
          >
            <p>{SIDEBAR_ACTIONS}</p>
          </Link>
          <Link
            to="/subscription"
            className="link"
            onClick={() => setIsNavVisible(false)}
          >
            <p>{SIDEBAR_SETTINGS}</p>
          </Link>
          <Link to="/" className="link" onClick={() => setIsNavVisible(false)}>
            <p onClick={handleLogout}>Logout</p>
          </Link>
        </div>
      )}
      <div className="nav">
        <button className="logout" onClick={handleLogout}>
          {MEETINGS_LOGOUT}
        </button>

        <div className="header-menu">
          <IoMdMenu onClick={toogleNav} className="header-menu" />
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
