import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './components/Meeting/home.scss';
import MeetingsProvider from './Providers/MeetingsProvider';
import Sidebar from './components/SidebarComp/Sidebar';
import Header from './components/Header/Header';
import Meeting from './components/Meeting/Meeting';
import Subscribe from 'components/SubscribeComp/Subscribe';
import UsersProvider from 'Providers/UsersProvider';
import NoteList from 'components/Note/NoteList';
import MeetingList from './components/Meeting/MeetingList';

const Layout: React.FC = () => {
  return (
    <div className="layout-container">
      <UsersProvider>
        <MeetingsProvider>
          <Header />
          <Sidebar />
          <Switch>
            <Route exact path="/">
              <MeetingList />
            </Route>
            <Route exact path="/meeting/:id">
              <Meeting />
            </Route>
            <Route exact path="/meeting/:id/notes">
              <Meeting />
            </Route>
            <Route path="/subscription">
              <Subscribe />
            </Route>
            <Route path="/account">
              <Subscribe />
            </Route>
            <Route path="/billing">
              <Subscribe />
            </Route>
            <Route path="/edit">
              <Subscribe />
            </Route>
            <Route path="/actions">
              <NoteList />
            </Route>
          </Switch>
        </MeetingsProvider>
      </UsersProvider>
    </div>
  );
};

export default React.memo(Layout);
