import DateFnsUtils from '@date-io/date-fns';
import { InputBase } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ThemeProvider } from '@material-ui/styles';
import CalendarBlue from 'assets/Calendaricon_blue.svg';
import NoteToolBox from 'components/Note/NoteToolBox';
import { dayDateFormat } from 'constants/formats';
import {
  MEETINGS_COLLECTION,
  MEETING_ACTIONS_ASSIGNED_BY_ME,
  MEETING_ACTIONS_ASSIGNED_TO_ME,
  MEETING_DESCRIPTION,
  MEETING_LAST_MEETINGS,
  MEETING_NAME,
  MEETING_NOTES,
  MEETING_PEOPLE,
  MEETING_SHARED_MEETINGS,
  NOTES_COLLECTION,
  USERS_COLLECTION,
} from 'constants/strings';
import { firestore } from 'FirebaseConfig';
import { Rights } from 'globals/Rights';
import { MeetingModel } from 'models/MeetingModel';
import { NoteModel } from 'models/NoteModel';
import { UserModel } from 'models/UserModel';
import moment from 'moment';
import { MeetingsContext } from 'Providers/MeetingsProvider';
import { UsersContext } from 'Providers/UsersProvider';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { IoIosArrowBack, IoMdTime } from 'react-icons/io';
import { Link, Route, Switch, useLocation, useParams } from 'react-router-dom';
import { collectIdsAndDocs } from 'utilities';
import Note from '../Note/Note';
import Person from '../Person/Person';
import PersonAdd from '../Person/PersonAdd';
import PersonRemove from '../Person/PersonRemove';
import AddNote from './AddNote';
import './meeting.scss';
import { catchErrorSnapshot, catchErrorGet } from 'utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      fontSize: 25,
    },
    marginInfo: {
      margin: theme.spacing(1),
    },
    marginInfoWeb: {
      margin: theme.spacing(1),
      width: 330,
    },
    margin: {
      margin: theme.spacing(1),
      fontSize: 23,
      width: 310,
    },
    marginMobile: {
      margin: theme.spacing(1),
      fontSize: 20,
      width: 260,
    },
    marginPickers: {
      margin: theme.spacing(1),
    },
    resize: {
      fontSize: 17,
      lineHeight: 1.1,
    },
    pickers: {
      fontSize: 16,
    },
  })
);

const theme = createMuiTheme({
  typography: {
    fontSize: 25,
  },
  palette: {
    primary: {
      main: '#00ADEE',
    },
  },
});

const Meeting: React.FC<{}> = () => {
  type Note = firebase.firestore.DocumentReference;

  const classes = useStyles();

  const { meeting, setMeeting } = useContext(MeetingsContext);

  const { profile } = useContext(UsersContext);

  const [notes, setNotes] = useState<Note[]>([]);

  const [meetingUsers, setMeetingUsers] = useState<UserModel[]>([]);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const subscription = firestore
        .collection(MEETINGS_COLLECTION)
        .doc(id)
        .onSnapshot(doc => {
          const meeting: MeetingModel = { id: doc.id, ...doc.data() };
          setMeeting && setMeeting(meeting);
        });
      return () => catchErrorSnapshot(subscription);
    }
  }, [id, setMeeting]);

  useEffect(() => {
    if (id) {
      const subscription = firestore
        .collection(NOTES_COLLECTION)
        .where('meeting', '==', id)
        .orderBy('date')
        .onSnapshot(snapshot => {
          const notes = snapshot.docs.map(doc =>
            firestore.collection(NOTES_COLLECTION).doc(doc.id)
          );
          setNotes(notes);
        });
      return () => catchErrorSnapshot(subscription);
    }
  }, [id]);

  useEffect(() => {
    if (meeting?.id) {
      const subscription = firestore
        .collection(USERS_COLLECTION)
        .where('uid', 'array-contains-any', meeting?.users)
        .get()
        .then(snapshot => {
          const meetingUsers = snapshot.docs.map(collectIdsAndDocs);
          setMeetingUsers(meetingUsers);
        });
      catchErrorGet(subscription);
    }
  }, [meeting]);

  const meetingQueryCompleted = id === meeting?.id;

  const subscription = profile && profile.subscription;

  const uid = profile?.id;

  const hasMeetingRights =
    meeting?.organizer === uid && subscription === Rights.Organizer;

  const noteRights = [Rights.Organizer, Rights.TeamMember];

  const hasNoteRights = subscription && noteRights.includes(subscription);

  const noteMenuDefault = useLocation().pathname.endsWith('/notes');

  const [notesToDo, setNotesToDo] = useState<Note[]>([]);

  const filterOrganizer = meetingUsers.filter(
    (user: UserModel) => user.id !== meeting?.organizer
  );

  const organizer = meetingUsers.filter(
    (user: UserModel) => user.id === meeting?.organizer
  );

  const addToDoNote = (note: Note): void => {
    setNotesToDo((prevNote: Note[] = []) => [...prevNote, note]);
  };

  const filterToDoNote = (note: Note): void => {
    setNotesToDo((prevNotes: Note[] = []) =>
      prevNotes.filter((prevNote: Note) => prevNote !== note)
    );
  };

  const deleteNotes = (): void => {
    notesToDo.forEach((note: Note) => {
      note.delete();
    });
    setNotesToDo([]);
  };

  const updateNotes = (): void => {
    notesToDo.length &&
      notesToDo.forEach((note: Note) => {
        note.get().then(document => {
          const noteDocument: NoteModel = {
            id: document.id,
            ...document.data(),
          };
          note.update({ checked: !noteDocument.checked });
        });
      });
    setNotesToDo([]);
  };

  const cancelNotes = (): void => {
    setNotesToDo([]);
  };

  const [addUserToList, setAddUserToList] = useState<string[]>([]);

  const addUsers = (newUser: string): void => {
    setAddUserToList((prevUsers: string[] = []) => [...prevUsers, newUser]);
  };

  const removeUsers = (removeUser: string): void => {
    setAddUserToList((prevUsers: string[] = []) =>
      prevUsers.filter((user: string) => user !== removeUser)
    );
  };

  const cancelUsers = (): void => {
    setAddUserToList([]);
  };

  const deleteUsers = (): void => {
    addUserToList &&
      firestore
        .collection(MEETINGS_COLLECTION)
        .doc(id)
        .update({
          users: meeting?.users?.filter(
            (user: string) => !addUserToList.includes(user)
          ),
        });
    setAddUserToList([]);
  };

  const dateChange = (
    date:
      | string
      | number
      | void
      | Date
      | moment.Moment
      | React.ReactText[]
      | moment.MomentInputObject
      | MaterialUiPickersDate
  ): void => {
    const dateFormat = 'DD-MM-YYYY';
    if (date) {
      moment(date, dateFormat, true).isValid() &&
        firestore
          .collection(MEETINGS_COLLECTION)
          .doc(id)
          .update({
            date: date,
          });
    }
  };

  const titleChange = (e: { currentTarget: { value: string } }): void => {
    firestore
      .collection(MEETINGS_COLLECTION)
      .doc(id)
      .update({
        title: e.currentTarget.value,
      });
  };

  const infoChange = (e: { currentTarget: { value: string } }): void => {
    firestore
      .collection(MEETINGS_COLLECTION)
      .doc(id)
      .update({
        info: e.currentTarget.value,
      });
  };

  return (
    <div className="meeting">
      {meetingQueryCompleted && (
        <React.Fragment>
          <div className="page-detail">
            <Link to="/">
              <IoIosArrowBack className="back" />
            </Link>
            <div className="web">
              <InputBase
                onBlur={titleChange}
                defaultValue={meeting?.title}
                className={classes.margin}
                disabled={!hasMeetingRights}
                inputProps={{ style: { color: '#00ADEE' } }}
              />
            </div>
            <div className="mobile">
              <InputBase
                onBlur={titleChange}
                defaultValue={meeting?.title}
                className={classes.marginMobile}
                disabled={!hasMeetingRights}
                inputProps={{ style: { color: 'white' } }}
              />
            </div>
          </div>
          <div className="date">
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <div className="flex-date">
                    <p className="day">
                      {moment(meeting?.date?.toDate()).format(dayDateFormat)}
                    </p>
                    <div className="top">
                      <KeyboardDatePicker
                        keyboardIcon={false}
                        disabled={!hasMeetingRights}
                        margin="normal"
                        id="date-picker-dialog"
                        format="dd/MM/yyyy"
                        value={meeting?.date?.toDate()}
                        onChange={dateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        className={classes.marginPickers}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: classes.pickers,
                          },
                          style: { color: '#62778c' },
                        }}
                      />
                    </div>
                    <img
                      src={CalendarBlue}
                      alt="Calendar"
                      className="calendar-picker"
                    />
                  </div>
                  <div className="flex">
                    <div className="top">
                      <KeyboardTimePicker
                        keyboardIcon={false}
                        minutesStep={5}
                        disabled={!hasMeetingRights}
                        margin="normal"
                        id="time-picker"
                        value={meeting?.date?.toDate()}
                        onChange={dateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        className={classes.marginPickers}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: classes.pickers,
                          },
                          style: { color: '#62778c' },
                        }}
                      />
                    </div>
                    <IoMdTime className="time-picker" />
                  </div>
                </Grid>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </div>
          <div className="meeting-info">
            <div className="web">
              <TextField
                defaultValue={meeting?.info}
                className={classes.marginInfo}
                onChange={infoChange}
                disabled={!hasMeetingRights}
                id="standard-multiline-flexible"
                placeholder={MEETING_DESCRIPTION}
                rowsMax="3"
                multiline
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                  style: { color: '#62778c' },
                }}
              />
            </div>
            <div className="mobile">
              <TextField
                defaultValue={meeting?.info}
                className={classes.marginInfoWeb}
                onChange={infoChange}
                disabled={!hasMeetingRights}
                placeholder={MEETING_DESCRIPTION}
                id="standard-multiline-flexible"
                rowsMax="3"
                multiline
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                  style: { color: '#62778c' },
                }}
              />
            </div>
          </div>
          {!addUserToList.length && !notesToDo.length ? (
            <div className="meeting-menu">
              <Link to={`/meeting/${meeting?.id}`} className="link">
                <p className={!noteMenuDefault ? 'basic' : 'active'}>
                  {MEETING_PEOPLE}
                </p>
              </Link>
              <Link to={`/meeting/${meeting?.id}/notes`} className="link">
                <p className={noteMenuDefault ? 'basic' : 'active'}>
                  {MEETING_NOTES}
                </p>
              </Link>
            </div>
          ) : !notesToDo.length ? (
            <PersonRemove cancel={cancelUsers} remove={deleteUsers} />
          ) : (
            <NoteToolBox
              cancelNote={cancelNotes}
              deleteNote={deleteNotes}
              markNote={updateNotes}
            />
          )}
          <div className="meeting-options-container">
            <Switch>
              <Route exact path={`/meeting/${meeting?.id}`}>
                <div className="people-container">
                  <div className="people-menu">
                    <p>{MEETING_NAME}</p>
                    <p>{MEETING_SHARED_MEETINGS}</p>
                    <p>{MEETING_LAST_MEETINGS}</p>
                    <p>{MEETING_ACTIONS_ASSIGNED_TO_ME}</p>
                    <p>{MEETING_ACTIONS_ASSIGNED_BY_ME}</p>
                  </div>
                  {organizer &&
                    organizer.map(
                      (organizer: UserModel): ReactNode => (
                        <div key={organizer.id}>
                          <Person
                            fullName={organizer.displayName}
                            personImage={organizer.photoURL}
                            lastMeetingID={organizer.id}
                            key={organizer.id}
                            uid={organizer.id}
                            actualMeeting={meeting?.id}
                          />
                        </div>
                      )
                    )}
                  {filterOrganizer &&
                    filterOrganizer.map(
                      (user: UserModel): ReactNode => (
                        <div key={user.id}>
                          <Person
                            fullName={user.displayName}
                            personImage={user.photoURL}
                            handleRemove={
                              hasMeetingRights
                                ? () => removeUsers(user.id!)
                                : undefined
                            }
                            handleAdd={
                              hasMeetingRights
                                ? () => addUsers(user.id!)
                                : undefined
                            }
                            toogleSelected={
                              hasMeetingRights
                                ? addUserToList.some(
                                    (id: string) => id === user.id
                                  )
                                : undefined
                            }
                            lastMeetingID={user.id}
                            uid={user.id!}
                            actualMeeting={meeting?.id}
                          />
                        </div>
                      )
                    )}
                  {hasMeetingRights && <PersonAdd />}
                </div>
              </Route>
              <Route exact path={`/meeting/${meeting?.id}/notes`}>
                {notes &&
                  notes.map(
                    (note: Note): ReactNode => (
                      <div key={note.id} className="action-detail">
                        <Note
                          noteDocument={note}
                          disabled={!hasNoteRights}
                          toogleSelected={notesToDo.some(
                            (action: NoteModel) => action.id === note.id
                          )}
                          handleAddAction={
                            hasNoteRights ? () => addToDoNote(note) : undefined
                          }
                          handleFilterAction={
                            hasNoteRights
                              ? () => filterToDoNote(note)
                              : undefined
                          }
                        />
                      </div>
                    )
                  )}
                {hasNoteRights && <AddNote />}
              </Route>
            </Switch>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default React.memo(Meeting);
