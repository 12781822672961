import React, {
  useState,
  useEffect,
  useContext,
  ReactNode,
  createContext,
} from 'react';
import { firestore } from '../FirebaseConfig';
import { collectIdsAndDocs } from 'utilities';
import { USERS_COLLECTION } from 'constants/strings';
import { AuthContext } from 'FirebaseAuthProvider ';
import { MeetingsContext } from './MeetingsProvider';
import { UserModel } from 'models/UserModel';
import { SuggestionDataItem, DataFunc } from 'react-mentions';
import { catchErrorSnapshot } from 'utils/utils';

type UserContextProps = {
  profile: UserModel | undefined;
  organizer: UserModel | undefined;
  users: SuggestionDataItem[] | DataFunc;
};

export const UsersContext = createContext<Partial<UserContextProps>>({});

interface UsersProps {
  children: ReactNode;
}

const UsersProvider: React.FC<UsersProps> = ({ children }: UsersProps) => {
  const [profile, setProfile] = useState<UserModel | undefined>();

  const [organizer, setOrganizer] = useState<UserModel | undefined>();

  const [users, setUsers] = useState<SuggestionDataItem[] | DataFunc>([]);

  const { meeting } = useContext(MeetingsContext);

  const { currentUser } = useContext(AuthContext);

  const uid = currentUser?.uid;

  useEffect(() => {
    if (uid) {
      const subscription = firestore
        .collection(USERS_COLLECTION)
        .doc(uid)
        .onSnapshot(snapshot => setProfile(collectIdsAndDocs(snapshot)));
      return () => catchErrorSnapshot(subscription);
    }
  }, [uid]);

  useEffect(() => {
    if (meeting) {
      const subscription = firestore
        .collection(USERS_COLLECTION)
        .doc(meeting.organizer)
        .onSnapshot(snapshot => setOrganizer(collectIdsAndDocs(snapshot)));
      return () => catchErrorSnapshot(subscription);
    }
  }, [meeting]);

  useEffect(() => {
    const subscription = firestore
      .collection(USERS_COLLECTION)
      .onSnapshot(snapshot => {
        const currentUserDocument = snapshot.docs.filter(doc => doc.id === uid);
        const usersDocuments = snapshot.docs.filter(doc => doc.id !== uid);
        const sortedDocuments = [...currentUserDocument, ...usersDocuments];
        setUsers(sortedDocuments.map(collectIdsAndDocs));
      });
    return () => catchErrorSnapshot(subscription);
  }, [uid]);

  return (
    <UsersContext.Provider
      value={{
        profile,
        organizer,
        users,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export default UsersProvider;
