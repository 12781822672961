enum MeetingFilter {
  All = 'all',
  OrganizedByMe = 'organized-by-me',
  AttendedByMe = 'attended-by-me',
}

export const meetingsFilter = (
  user: string,
  startAt?: number,
  endAt?: number,
  meetingFilter?: MeetingFilter
): string => {
  let queryFilter: string;

  switch (meetingFilter) {
    case MeetingFilter.All:
      queryFilter = `AND (organizer:${user} OR attendees:${user})`;
      break;
    case MeetingFilter.OrganizedByMe:
      queryFilter = `AND organizer:${user}`;
      break;
    case MeetingFilter.AttendedByMe:
      queryFilter = `AND (NOT organizer:${user} AND attendees:${user})`;
      break;
    default:
      queryFilter = `AND (organizer:${user} OR attendees:${user})`;
  }

  const query = `date_timestamp${
    startAt && endAt ? ':' : startAt ? ' >= ' : ' <= '
  }${startAt ? startAt : ''}${startAt && endAt ? ' TO ' : ''}${
    endAt ? endAt : ''
  } ${queryFilter}`;
  return query;
};
