export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  highlighter: {
    overflow: 'hidden',
  },

  input: {
    margin: 0,
  },

  '&singleLine': {
    control: {
      display: 'inline-block',
      width: 130,
      color: 'white',
    },

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
      color: 'white',
    },

    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  '&multiLine': {
    control: {
      color: '#62778c',
      fontFamily: 'SF Display',
      border: '0px solid silver',
      fontSize: 16,
    },

    highlighter: {
      padding: 9,
      color: 'white',
    },

    input: {
      color: '#62778c',
      padding: 9,
      minHeight: 63,
      outline: 0,
      border: 0,
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '0px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      color: 'white',
    },

    item: {
      padding: '1px 15px',
      borderBottom: '0px solid rgba(0,0,0,0.15)',

      '&focused': {
        backgroundColor: '#417505',
        color: 'white',
      },
    },
  },
}
