import moment from 'moment';
import firebase from 'firebase';

export const startOfLastWeek = firebase.firestore.Timestamp.fromDate(
  moment()
    .subtract(1, 'week')
    .startOf('week')
    .toDate()
).seconds;

export const startOfThisWeek = firebase.firestore.Timestamp.fromDate(
  moment()
    .startOf('week')
    .toDate()
).seconds;

export const endOfThisWeek = firebase.firestore.Timestamp.fromDate(
  moment()
    .endOf('week')
    .toDate()
).seconds;

export const endOfNextWeek = firebase.firestore.Timestamp.fromDate(
  moment()
    .add(1, 'week')
    .endOf('week')
    .toDate()
).seconds;

export const startOfLastMonth = firebase.firestore.Timestamp.fromDate(
  moment()
    .subtract(1, 'month')
    .startOf('month')
    .toDate()
).seconds;

export const startOfThisMonth = firebase.firestore.Timestamp.fromDate(
  moment()
    .startOf('month')
    .toDate()
).seconds;

export const endOfThisMonth = firebase.firestore.Timestamp.fromDate(
  moment()
    .endOf('month')
    .toDate()
).seconds;

export const endOfNextMonth = firebase.firestore.Timestamp.fromDate(
  moment()
    .add(1, 'month')
    .endOf('month')
    .toDate()
).seconds;

export const startOfThisYear = firebase.firestore.Timestamp.fromDate(
  moment()
    .startOf('year')
    .toDate()
).seconds;
