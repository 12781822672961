import { dayDateFormat, fullDateFormat } from 'constants/formats';
import { MeetingModel } from 'models/MeetingModel';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './home.scss';
import MeetingTimeLine from './MeetingTimeLine';
import { catchErrorSnapshot } from 'utils/utils';

interface MeetingItemProps {
  meetingDocument: firebase.firestore.DocumentReference;
}

const MeetingItem: React.FC<MeetingItemProps> = ({
  meetingDocument,
}: MeetingItemProps) => {
  const [meeting, setMeeting] = useState<MeetingModel | undefined>(undefined);

  useEffect(() => {
    if (meetingDocument) {
      const subscription = meetingDocument.onSnapshot(doc => {
        const meeting: MeetingModel = { id: doc.id, ...doc.data() };
        setMeeting(meeting);
      });
      return () => catchErrorSnapshot(subscription);
    }
  }, [meetingDocument]);

  return (
    <React.Fragment>
      {meeting && (
        <Link
          to={`/meeting/${meeting.id}/notes`}
          key={meeting.id}
          className="link"
        >
          <MeetingTimeLine
            key={meeting.id}
            meetingDate={moment(meeting?.date?.toDate()).format(fullDateFormat)}
            meetingDay={moment(meeting?.date?.toDate()).format(dayDateFormat)}
            meetingInfo={meeting.title}
          />
        </Link>
      )}
    </React.Fragment>
  );
};

export default React.memo(MeetingItem);
