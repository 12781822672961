export const collectIdsAndDocs = (doc: { id: string; data: () => any }) => {
  return { id: doc.id, ...doc.data() };
};

export const noteIDsExtract = (note: string) => {
  const ExtractId = (input: string): string[] => {
    const userID = input.toString().split('user:');
    return userID;
  };

  const ids = ExtractId(note);

  const finalIds: string[] = ids
    .map((element: string) => element.substring(0, 28))
    .slice(1);

  return finalIds;
};
