import React from 'react'
import './notes.scss'
import { AiOutlineDelete } from 'react-icons/ai'
import { MdCancel } from 'react-icons/md'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'

interface NoteToolBoxProps {
  cancelNote: () => void;
  deleteNote: () => void;
  markNote: () => void;
}

const NoteToolBox: React.FC<NoteToolBoxProps> = ({ cancelNote, deleteNote, markNote }: NoteToolBoxProps) => (<div className="person-remove">
  <button className="button" onClick={cancelNote}>
    <MdCancel className="icon-cancel" />
    <p className="text">Cancel</p>
  </button>
  <button className="button" onClick={deleteNote}>
    <AiOutlineDelete className="icon" />
    <p className="text">Delete</p>
  </button>
  <button className="button" onClick={markNote}>
    <IoIosCheckmarkCircleOutline className="icon-mark" />
    <p className="text">Done</p>
  </button>
</div>)

export default NoteToolBox
