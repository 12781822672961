import React, { useEffect } from 'react';
import './app.scss';
import { Switch, Route, useLocation } from 'react-router-dom';
import Headerlogin from './components/Header/Headerlogin';
import Loginpage from './components/Login/Loginpage';
import Layout from './Layout';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from 'FirebaseAuthProvider ';

const App: React.FC = () => {
  const location: { state: { from: string } } = useLocation();

  useEffect(() => {
    if (location?.state?.from) {
      sessionStorage.setItem('url', location.state.from);
    }
  }, [location]);

  return (
    <AuthProvider>
      <div className="app-container">
        <p className="product-version">v{process.env.REACT_APP_VERSION}</p>
        <Switch>
          <Route exact path="/login">
            <Headerlogin />
            <Loginpage />
          </Route>
          <PrivateRoute path="/" component={Layout} />
        </Switch>
      </div>
    </AuthProvider>
  );
};

export default React.memo(App);
