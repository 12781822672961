import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import {
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_SENDER_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_DATABASE,
  REACT_APP_FIREBASE_DOMAIN,
  REACT_APP_FIREBASE_KEY,
  REACT_APP_STRIPE_KEY_ORGANIZER_PLAN,
} from 'utils/config';
import * as firebaseui from 'firebaseui';
import { Rights } from 'globals/Rights';

export const config = {
  apiKey: REACT_APP_FIREBASE_KEY!,
  authDomain: REACT_APP_FIREBASE_DOMAIN!,
  databaseURL: REACT_APP_FIREBASE_DATABASE!,
  projectId: REACT_APP_FIREBASE_PROJECT_ID!,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET!,
  messagingSenderId: REACT_APP_FIREBASE_SENDER_ID!,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(config);

window.firebase = firebase;

const ui = new firebaseui.auth.AuthUI(window.firebase.auth());

export const firestore = firebase.firestore();
export const timestamp = firebase.firestore.Timestamp;
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();

export const createUserDocument = async (
  user: firebase.User,
  additionalData?: firebase.firestore.DocumentData
): Promise<void> => {
  if (!user) return;

  const userRef = firestore.doc(`users/${user.uid}`);

  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const { displayName, email, photoURL, uid } = user;

    if (!displayName || !email) {
      return;
    }

    try {
      await userRef.set({
        uid: [uid],
        display: displayName,
        displayName: displayName,
        email,
        photoURL,
        createdAt: new Date(),
        subscription: Rights.Organizer,
        ...additionalData,
      });

      const createStripeCustomerWithFreeTrial = functions.httpsCallable(
        'createStripeCustomerWithFreeTrial'
      );

      const date = new Date();
      const trial30 = date.setDate(date.getDate() + 30);
      const convertFromMiliseconds = Math.round(trial30 / 1000);

      createStripeCustomerWithFreeTrial({
        uid: uid,
        email: email,
        name: displayName,
        subscriptionPlan: REACT_APP_STRIPE_KEY_ORGANIZER_PLAN,
        trial: convertFromMiliseconds,
      });
    } catch (error) {
      console.error('Error creating user', console.error);
    }
  }
};

export const uiconfig = {
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    },
    {
      provider: 'microsoft.com',
      loginHintKey: 'login_hint',
      buttonColor: '#00ADEE',
    },
  ],
  signInSuccessUrl: `#${sessionStorage?.url}`,
};

export const startFirebaseUI = (elementId: string | Element): void => {
  ui.start(elementId, uiconfig);
};

export default firebase;
