import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import defaultStyle from './stylesMentionsComp/defaultStyle';
import defaultMentionStyle from './stylesMentionsComp/defaultMentionStyle';
import { firestore, timestamp } from 'FirebaseConfig';
import { noteIDsExtract } from 'utilities';
import { IoIosAddCircleOutline } from 'react-icons/io';
import moment from 'moment';
import classNames from './stylesMentionsComp/defaultCSS.module.css';
import { AuthContext } from 'FirebaseAuthProvider ';
import { ACTION_ADD, NOTES_COLLECTION } from 'constants/strings';
import hotkeys, { HotkeysEvent } from 'hotkeys-js';
import { MeetingsContext } from 'Providers/MeetingsProvider';
import { UsersContext } from 'Providers/UsersProvider';

const AddNote: React.FC<{}> = () => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const [note, setNote] = useState<string>('');

  const { meeting } = useContext(MeetingsContext);

  const { users } = useContext(UsersContext);

  const addNote = (event: { target: { value: string } }): void => {
    setNote(event.target.value);
  };

  const assignedIds = noteIDsExtract(note);

  const { currentUser } = useContext(AuthContext);

  const onSubmit = React.useCallback(() => {
    if (currentUser && meeting && note) {
      firestore.collection(NOTES_COLLECTION).add({
        note: note,
        uid: currentUser?.uid,
        date: timestamp.fromDate(moment().toDate()),
        checked: false,
        shared: true,
        assigned: assignedIds,
        connected: [currentUser?.uid, ...assignedIds],
        isAction: assignedIds.length > 0,
        meeting: meeting.id,
      });
    }
    setNote('');
    ref?.current?.focus();
  }, [currentUser, note, meeting, assignedIds]);

  type CallbackFn = (event: KeyboardEvent, handler: HotkeysEvent) => void;

  const useHotkeys = (
    keys: string,
    callback: CallbackFn,
    deps: string
  ): void => {
    const hotKeyCallback = useCallback(callback, [deps]);

    useEffect(() => {
      hotkeys.filter = () => {
        return true;
      };
      hotkeys(keys, hotKeyCallback);

      return () => hotkeys.unbind(keys);
    }, [hotKeyCallback, keys]);
  };

  useHotkeys('ctrl+i', () => onSubmit(), note);
  useHotkeys('command+i', () => onSubmit(), note);

  return (
    <div className="actions-container">
      <div className="name">
        <IoIosAddCircleOutline className="add-icon" onClick={onSubmit} />
        <div className="mentions-box">
          <MentionsInput
            inputRef={ref}
            style={defaultStyle}
            value={note}
            onChange={addNote}
            placeholder={ACTION_ADD}
            allowSpaceInQuery={true}
          >
            <Mention
              style={defaultMentionStyle}
              className={classNames.mentions__mention}
              trigger="@"
              data={users!}
              markup="@[__display__](user:__id__)"
              renderSuggestion={(_, __, highlightedDisplay, focused) => (
                <div className={`user ${focused ? 'focused' : ''}`}>
                  {highlightedDisplay}
                </div>
              )}
            />
          </MentionsInput>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddNote);
