import React from 'react';
import './person.scss';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';

interface PersonRemoveProps {
  cancel?: () => void;
  remove?: () => void;
}

const PersonRemove: React.FC<PersonRemoveProps> = ({ cancel, remove }) => {
  return (
    <div className="person-remove">
      <button className="button" onClick={cancel}>
        <MdCancel className="icon-cancel" />
        <p className="text">Cancel</p>
      </button>
      <button className="button" onClick={remove}>
        <AiOutlineDelete className="icon" />
        <p className="text">Delete</p>
      </button>
    </div>
  );
};

export default PersonRemove;
