import CalendarBlue from 'assets/Calendaricon_blue.svg';
import CalendarGray from 'assets/Calendaricon_gray.svg';
import avatar from 'assets/personavatar.png';
import AcionsBlue from 'assets/Rocketicon_blue.svg';
import AcionsGray from 'assets/Rocketicon_grey.svg';
import {
  SIDEBAR_ACTIONS,
  SIDEBAR_MEETING,
  SIDEBAR_SETTINGS,
} from 'constants/strings';
import { UsersContext } from 'Providers/UsersProvider';
import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './sidebar.scss';

const Sidebar: React.FC<{}> = () => {
  const { profile } = useContext(UsersContext);

  const [imageSrc, setImageSrc] = useState(true);

  const addDefaultsrc = (): void => {
    setImageSrc(false);
  };

  const location = useLocation().pathname;

  const HasAppRoutes = location.startsWith('/meeting') || location === '/';

  const hasActionRoutes = ['/actions'].includes(location);

  const HasAccountRoutes = ['/subscription', '/account', '/billing'].includes(
    location
  );

  return (
    <div className="side-bar">
      <div className="icon-sidebar">
        <Link to="/">
          <img
            src={HasAppRoutes ? CalendarBlue : CalendarGray}
            alt="Calendar"
            className="calendar"
          />
        </Link>
        <p className={HasAppRoutes ? 'sidebar-text' : 'none'}>
          {SIDEBAR_MEETING}
        </p>
      </div>
      <div className="icon-sidebar">
        <Link to="/actions">
          <img
            src={hasActionRoutes ? AcionsBlue : AcionsGray}
            alt="Actions"
            className="actions-rocket"
          />
        </Link>
        <p className={hasActionRoutes ? 'sidebar-text' : 'none'}>
          {SIDEBAR_ACTIONS}
        </p>
      </div>
      <div className="icon-sidebar">
        <Link to="/subscription">
          {imageSrc && profile && profile.photoURL !== null ? (
            <img
              src={profile && profile.photoURL}
              alt="person"
              onError={addDefaultsrc}
            />
          ) : (
            <img src={avatar} alt="person" />
          )}
        </Link>
        <p className={HasAccountRoutes ? 'sidebar-text' : 'none'}>
          {SIDEBAR_SETTINGS}
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
