import checkIcon from 'assets/icon-check.svg';
import avatar from 'assets/personavatar.png';
import { ACTION_ADD, USERS_COLLECTION } from 'constants/strings';
import { firestore } from 'FirebaseConfig';
import { NoteModel } from 'models/NoteModel';
import { UsersContext } from 'Providers/UsersProvider';
import React, {useContext, useEffect, useRef, useState} from 'react';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { Mention, MentionsInput } from 'react-mentions';
import { collectIdsAndDocs, noteIDsExtract } from 'utilities';
import classNames from '../Meeting/stylesMentionsComp/defaultCSS.module.css';
import defaultMentionStyle from '../Meeting/stylesMentionsComp/defaultMentionStyle';
import defaultStyle from '../Meeting/stylesMentionsComp/defaultStyle';
import { catchErrorSnapshot } from 'utils/utils';

interface NoteProps {
  noteDocument: firebase.firestore.DocumentReference;
  toogleSelected?: boolean;
  handleAddAction?: () => void;
  handleFilterAction?: () => void;
  disabled?: boolean;
}

const styles = {
  mentions: {},
};

const Note: React.FC<NoteProps> = ({
  noteDocument,
  toogleSelected,
  handleFilterAction,
  handleAddAction,
  disabled,
}: NoteProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const { users } = useContext(UsersContext);

  const [userPhoto, setUserPhoto] = useState<string | null>(null);

  const [imageSrc, setImageSrc] = useState(true);

  const [note, setNote] = useState<NoteModel | undefined>(undefined);

  const [cursor, setCursor] = useState<number>(0);

  const [updateContent, setUpdateContent] = useState('');

  useEffect(() => {
    if (noteDocument) {
      const subscription = noteDocument.onSnapshot(doc => {
        const noteModel: NoteModel = { id: doc.id, ...doc.data() };
        setNote(noteModel);
        if (noteModel && noteModel.note) {
          setUpdateContent(noteModel.note);
        }
      });
      return () => catchErrorSnapshot(subscription);
    }
  }, [noteDocument]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.setSelectionRange(cursor, cursor);
    }
  }, [updateContent, cursor])

  const addDefaultsrc = (): void => {
    setImageSrc(!imageSrc);
  };

  useEffect(() => {
    if (note && note.uid) {
      firestore
        .collection(USERS_COLLECTION)
        .doc(note.uid)
        .onSnapshot(snapshot => {
          const user = collectIdsAndDocs(snapshot);
          setUserPhoto(user.photoURL);
        });
    }
  }, [note]);

  const updateNote = async (event: { target: { value: string } }): Promise<void> => {
    setUpdateContent(event.target.value);
    if (ref && ref.current) {
      setCursor(ref.current.selectionStart);
    }

    if (noteDocument) {
      const assignedIds = noteIDsExtract(event.target.value);

      const newAction = {
        note: event.target.value,
        isAction: assignedIds.length > 0,
        assigned: assignedIds,
        connected: [noteDocument.id, ...assignedIds],
      };

      await noteDocument.update(newAction);
    }
  };

  return (
    <div className="actions-container">
      <div className="name">
        {toogleSelected ? (
          <img
            src={checkIcon}
            alt="check"
            className="person-icon"
            onClick={handleFilterAction}
          />
        ) : imageSrc && userPhoto !== null ? (
          <img
            src={userPhoto}
            alt="person"
            onError={addDefaultsrc}
            className="person-icon"
            onClick={handleAddAction}
          />
        ) : (
          <img
            src={avatar}
            alt="person"
            className="person-icon"
            onClick={handleAddAction}
          />
        )}
        <div className="mentions-box" style={styles.mentions}>
          <MentionsInput
            className="mentions"
            disabled={disabled}
            style={defaultStyle}
            value={updateContent}
            onChange={updateNote}
            inputRef={ref}
            placeholder={ACTION_ADD}
            allowSpaceInQuery={true}
          >
            <Mention
              className={classNames.mentions__mention}
              style={defaultMentionStyle}
              trigger="@"
              data={users!}
              markup="@[__display__](user:__id__)"
              renderSuggestion={(
                _suggestion,
                _search,
                highlightedDisplay,
                _index,
                focused
              ) => (
                <div className={`user ${focused ? 'focused' : ''}`}>
                  {highlightedDisplay}
                </div>
              )}
            />
          </MentionsInput>
        </div>
        {note?.isAction ? (
          <div className="menu">
            <IoIosCheckmarkCircleOutline
              className={note.checked ? 'checked' : 'unchecked'}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(Note);
