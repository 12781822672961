import React, { useState, useEffect, useContext } from 'react';
import './person.scss';
import avatar from 'assets/personavatar.png';
import checkIcon from 'assets/icon-check.svg';
import { firestore } from 'FirebaseConfig';
import { collectIdsAndDocs } from 'utilities';
import TextTruncate from 'react-text-truncate';
import {
  MEETING_NO_TITLE,
  MEETINGS_COLLECTION,
  NOTES_COLLECTION,
} from 'constants/strings';
import { UsersContext } from 'Providers/UsersProvider';
import { MeetingModel } from 'models/MeetingModel';
import { catchErrorGet } from 'utils/utils';

interface PersonProps {
  fullName: string | undefined;
  personImage?: string | undefined;
  handleRemove?: () => void | undefined;
  handleAdd?: () => void | undefined;
  toogleSelected?: boolean | undefined;
  lastMeetingID: string | undefined;
  uid: string | undefined;
  actualMeeting?: string | undefined;
}

const Person: React.FC<PersonProps> = ({
  lastMeetingID,
  actualMeeting,
  uid,
  toogleSelected,
  handleRemove,
  personImage,
  handleAdd,
  fullName,
}: PersonProps) => {
  const [imageSrc, setImageSrc] = useState(true);

  const addDefaultsrc = (): void => {
    setImageSrc(!imageSrc);
  };

  const [lastMeeting, setLastMeeting] = useState<MeetingModel[] | undefined>();

  const [numberOfMeetings, setNumberOfMeetings] = useState<
    MeetingModel[] | undefined
  >();
  const [numberOfActionsToMe, setNumberOfActionsToMe] = useState<number>(0);

  const [numberOfActionsByMe, setNumberOfActionsByMe] = useState<number>(0);

  const { profile } = useContext(UsersContext);
  const ownerUID = profile && profile.id;

  useEffect(() => {
    if (lastMeetingID) {
      const subscription = firestore
        .collection(MEETINGS_COLLECTION)
        .where('users', 'array-contains', lastMeetingID)
        .orderBy('date', 'desc')
        .get()
        .then(snapshot =>
          setLastMeeting(
            snapshot.docs
              .map(collectIdsAndDocs)
              .filter(meeting => meeting.users.includes(ownerUID))
              .filter(meeting => meeting.id !== actualMeeting)
          )
        );
      catchErrorGet(subscription);
    }
  }, [lastMeetingID, actualMeeting, ownerUID]);

  useEffect(() => {
    if (lastMeetingID) {
      const subscription = firestore
        .collection(MEETINGS_COLLECTION)
        .where('users', 'array-contains', lastMeetingID)
        .get()
        .then(snapshot =>
          setNumberOfMeetings(
            snapshot.docs
              .map(collectIdsAndDocs)
              .filter(meeting => meeting.users.includes(ownerUID))
          )
        );
      catchErrorGet(subscription);
    }
  }, [lastMeetingID, ownerUID]);

  useEffect(() => {
    if (lastMeetingID) {
      const subscription = firestore
        .collection(NOTES_COLLECTION)
        .where('uid', '==', uid)
        .where('assigned', 'array-contains', ownerUID)
        .get()
        .then(snapshot =>
          setNumberOfActionsToMe(snapshot.docs.map(collectIdsAndDocs).length)
        );
      catchErrorGet(subscription);
    }
  }, [lastMeetingID, uid, ownerUID]);

  useEffect(() => {
    if (lastMeetingID) {
      const subscription = firestore
        .collection(NOTES_COLLECTION)
        .where('uid', '==', ownerUID)
        .where('assigned', 'array-contains', uid)
        .get()
        .then(snapshot =>
          setNumberOfActionsByMe(snapshot.docs.map(collectIdsAndDocs).length)
        );
      catchErrorGet(subscription);
    }
  }, [lastMeetingID, uid, ownerUID]);

  return (
    <div className="person">
      <div className="name">
        {toogleSelected ? (
          <img
            src={checkIcon}
            alt="check"
            className="person-icon"
            onClick={handleRemove}
          />
        ) : imageSrc && personImage !== null ? (
          <img
            alt="avatar"
            src={personImage}
            className="personicon"
            onClick={handleAdd}
            onError={addDefaultsrc}
          />
        ) : (
          <img
            alt="avatar"
            src={avatar}
            className="person-icon"
            onClick={handleAdd}
          />
        )}
        <p>{fullName}</p>
      </div>
      <p className="hide">{numberOfMeetings && numberOfMeetings.length}</p>
      <div className="last-meeting">
        <div className="hide">
          {lastMeeting && lastMeeting.length > 0 ? (
            <div className="people-truncate">
              <TextTruncate
                line={2}
                element="span"
                truncateText="…"
                text={lastMeeting && lastMeeting[0].title}
              />
            </div>
          ) : (
            <p>{MEETING_NO_TITLE}</p>
          )}
        </div>
      </div>
      <p className="hide">{numberOfActionsToMe}</p>
      <p className="hide">{numberOfActionsByMe}</p>
    </div>
  );
};

export default React.memo(Person);
