import React, { useEffect, useContext } from 'react';
import './loginpage.scss';
import { startFirebaseUI } from 'FirebaseConfig';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'FirebaseAuthProvider ';

const Loginpage: React.FC = () => {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    startFirebaseUI('#firebaseui');
  }, []);

  if (currentUser) {
    return <Redirect to={sessionStorage?.url} />;
  }

  return (
    <div className="login">
      <div className="title"></div>
      <div id="firebaseui"></div>
    </div>
  );
};

export default Loginpage;
