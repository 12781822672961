import React, { useEffect, useState, ReactNode } from 'react';
import { auth, createUserDocument, functions } from 'FirebaseConfig';
import { User } from 'firebase';

type AuthContextProps = {
  currentUser: User | null;
};

export const AuthContext = React.createContext<Partial<AuthContextProps>>({});

interface AuthProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProps> = ({ children }: AuthProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>();

  useEffect(() => {
    auth.onAuthStateChanged(setCurrentUser);
    if (currentUser) {
      createUserDocument(currentUser);
    }
  }, [currentUser]);

  const uid = currentUser?.uid;

  const checkStripeSubscription = functions.httpsCallable(
    'checkStripeSubscription'
  );

  useEffect(() => {
    if (uid) {
      checkStripeSubscription({
        uid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkStripeSubscription]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
