import { algolia, algoliaNoteSearch } from 'AlgoliaConfig';
import {
  ACTION_ACTIONS,
  ACTION_ALL,
  ACTION_ASSIGNED_BY_ME,
  ACTION_ASSIGNED_TO_ME,
  ACTION_SEARCH,
  ACTION_SHOW_COMPLETED,
  UI_COLLECTION,
} from 'constants/strings';
import { AuthContext } from 'FirebaseAuthProvider ';
import { auth, firestore } from 'FirebaseConfig';
import { Rights } from 'globals/Rights';
import { NoteModel } from 'models/NoteModel';
import { UsersContext } from 'Providers/UsersProvider';
import React, { useContext, useEffect, useState } from 'react';
import Switch from 'react-switch';
import { catchErrorSnapshot } from 'utils/utils';
import '../Meeting/home.scss';
import Note from './Note';
import NoteToolBox from './NoteToolBox';

import { NoteFilter } from './NoteFilter';

export type Note = firebase.firestore.DocumentReference;

const NoteList: React.FC = () => {
  const { profile } = useContext(UsersContext);

  const subscription = profile && profile.subscription;

  const [isChecked, setIsChecked] = useState(false);

  const noteCheck = (checked: boolean): void => {
    setIsChecked(checked);
  };

  const [notesToDo, setNotesToDo] = useState<Note[]>([]);

  const addToDoNote = (note: Note): void => {
    setNotesToDo((prevNote: Note[] = []) => [...prevNote, note]);
  };

  const filterToDoNote = (note: Note): void => {
    setNotesToDo((prevNotes: Note[] = []) =>
      prevNotes.filter((prevNote: Note) => prevNote !== note)
    );
  };

  const [filter, setFilter] = useState(NoteFilter.All);

  const { currentUser } = useContext(AuthContext);

  const uid = currentUser?.uid;

  const [notes, setNotes] = useState<Note[]>([]);

  const [searchText, setSearchText] = useState<string>('');

  const search = (event: { target: { value: string } }): void => {
    setSearchText(event.target.value);
  };

  const callBackNotes = (uid: string): void =>
    algoliaNoteSearch(searchText, uid, isChecked, filter, setNotes);

  useEffect(() => {
    if (uid) {
      algolia.clearCache();
      callBackNotes(uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, uid, filter, isChecked, notesToDo]);

  useEffect(() => {
    if (uid) {
      const subscription = firestore
        .collection(UI_COLLECTION)
        .doc('note_list_component')
        .onSnapshot(() => {
          algolia.clearCache();
          callBackNotes(uid);
        });
      return () => catchErrorSnapshot(subscription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const deleteNotes = (): void => {
    notesToDo.forEach((note: Note) => {
      note.delete();
    });
    setNotesToDo([]);
  };

  const updateNotes = (): void => {
    notesToDo.forEach((note: Note) => {
      note.get().then(document => {
        const noteDocument: NoteModel = { id: document.id, ...document.data() };
        note.update({ checked: !noteDocument.checked });
      });
    });
    setNotesToDo([]);
  };

  const cancelNotes = (): void => {
    setNotesToDo([]);
  };

  const noteRights = [Rights.Organizer, Rights.TeamMember];

  const hasNoteRights = subscription && noteRights.includes(subscription);

  return (
    auth.currentUser && (
      <div className="home">
        <div className="horizontal-line"></div>
        <div className="meetings">
          <p>{ACTION_ACTIONS}</p>
        </div>
        <div className="search">
          <input
            type="text"
            placeholder={ACTION_SEARCH}
            value={searchText}
            onChange={search}
            className="input"
          />
        </div>
        {hasNoteRights && (
          <div className="new-add-container">
            <div className="show-completed ">
              <p>{ACTION_SHOW_COMPLETED}</p>
              <Switch
                onChange={noteCheck}
                checked={isChecked}
                height={20}
                width={40}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#00ADEE"
              />
            </div>
          </div>
        )}
        {!notesToDo.length ? (
          <React.Fragment>
            <div className="all" onClick={() => setFilter(NoteFilter.All)}>
              <p
                className={
                  filter === NoteFilter.All ? 'active-main-menu' : 'none'
                }
              >
                {ACTION_ALL}
              </p>
            </div>
            <div className="organised">
              <p
                className={
                  filter === NoteFilter.AssignedToMe
                    ? 'active-main-menu'
                    : 'none'
                }
                onClick={() => setFilter(NoteFilter.AssignedToMe)}
              >
                {ACTION_ASSIGNED_TO_ME}
              </p>
            </div>
            <div className="assigned">
              <p
                className={
                  filter === NoteFilter.AssignedByMe
                    ? 'active-main-menu'
                    : 'none'
                }
                onClick={() => setFilter(NoteFilter.AssignedByMe)}
              >
                {ACTION_ASSIGNED_BY_ME}
              </p>
            </div>
          </React.Fragment>
        ) : (
          <NoteToolBox
            cancelNote={cancelNotes}
            deleteNote={deleteNotes}
            markNote={updateNotes}
          />
        )}

        <div className="week-container">
          {notes.map((note: Note) => (
            <div key={note.id} className="action-detail">
              <Note
                noteDocument={note}
                disabled={!hasNoteRights}
                handleAddAction={
                  hasNoteRights ? () => addToDoNote(note) : undefined
                }
                handleFilterAction={
                  hasNoteRights ? () => filterToDoNote(note) : undefined
                }
                toogleSelected={notesToDo.some(
                  (actionNote: NoteModel) => actionNote.id === note.id
                )}
              />
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default React.memo(NoteList);
