import { MeetingModel } from 'models/MeetingModel';
import { MEETINGS_COLLECTION, NOTES_COLLECTION } from 'constants/strings';
import { firestore } from 'FirebaseConfig';
import { NoteModel } from 'models/NoteModel';

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export const algoliaHitsToMeetingDocumentReference = (
  hits: MeetingModel[]
): firebase.firestore.DocumentReference[] =>
  hits
    .map((object: MeetingModel) => object.objectID)
    .filter(notEmpty)
    .map((documentId: string) =>
      firestore.collection(MEETINGS_COLLECTION).doc(documentId)
    );

export const algoliaHitsToNoteDocumentReference = (
  hits: NoteModel[]
): firebase.firestore.DocumentReference[] =>
  hits
    .map((object: NoteModel) => object.objectID)
    .filter(notEmpty)
    .map((documentId: string) =>
      firestore.collection(NOTES_COLLECTION).doc(documentId)
    );

export function catchErrorSnapshot(firestoreQuery: () => void): void {
  try {
    firestoreQuery();
  } catch (Error) {}
}

export function catchErrorGet(
  firestoreQuery: Promise<void>
): Promise<void> | undefined {
  try {
    return firestoreQuery;
  } catch (Error) {}
}
